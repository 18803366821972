/* Full-page container */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  background-color: #ffffff;
  background-size: cover;
  padding: 0;
  font-family: Arial, sans-serif;
  position: relative;
  overflow: hidden;
  padding-top: 150px;
  font-family: 'Montserrat', sans-serif;
}

/* Top Header with Fixed Position */
.top-header {
  position: fixed;  /* Keep the header fixed at the top */
  top: 0;           /* Position it at the top of the viewport */
  width: 100%;      /* Full width */
  display: flex;
  justify-content: space-between;  /* Spread the logos to the left and right */
  align-items: center;
  padding: 20px 30px;
  background-color: #ffffff;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  /* Optional shadow for styling */
}

/* Left Logo (Sales Sherpa Logo) */
.logo-left {
  height: 40px;
  flex-shrink: 0;  /* Prevent shrinking of the logo */
}

/* Right Logo (JKC Logo) */
.logo-right {
  height: 40px;
  margin-left: auto;  /* Push the right logo to the far right */
  flex-shrink: 0;  /* Prevent shrinking */
}

/* Layout for the main conversation area */
.conversation-layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;        /* Full height of the viewport */
  overflow: hidden;     /* Prevent scrolling */
  padding-top: 80px;    /* Space for the fixed top-header */
  box-sizing: border-box;
}


.top-header-title {
  font-size: 1.3rem;
  color: #000000;
  text-align: center;
  flex-grow: 1;
  font-family: 'Montserrat', sans-serif;
}

/* Avatar container */
.avatar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}

/* Avatar image */
.avatar-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-right: 20px;
  object-fit: cover;
  border: 5px solid white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Avatar container (circle) */
.logo-circle {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Profile icon inside the circle */
.logo-circle img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Chatbot title adjustments */
.chatbot-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  flex-grow: 1;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

/* Language selection buttons */
.language-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px; /* Space between buttons */
  width: 100%;
  max-width: 300px; /* Limit the button width */
  margin: 20px auto; /* Center the buttons horizontally */
  padding: 20px;
  background-color: #ffffff; /* Light background to distinguish from page */
  border-radius: 20px; /* Rounded corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  overflow: hidden; /* Prevent overflow */
  font-family: 'Montserrat', sans-serif;
}

/* Language button styles */
.language-button {
  background-color: #8CC63F; /* Set the green background */
  color: white; /* White text */
  border: none; /* Remove the default border */
  padding: 15px 30px; /* Add padding for a more modern, clickable feel */
  border-radius: 20px; /* Rounded corners for a smoother look */
  font-size: 1.5rem; /* Adjust the font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition */
  width: 100%; /* Make sure button takes up full width */
  text-align: center; /* Center the text */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

/* Hover and active states for language buttons */
.language-button:hover {
  background-color: #74A92F; /* Darker green on hover */
}

.language-button:active {
  background-color: #6CAB3D; /* Even darker green when clicked */
  transform: translateY(0); /* Reset position */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Shadow effect on press */
}



/* Button style for three dots */
.menu-button {
  font-size: 1.5rem;
  color: white;
  background-color: #8CC63F;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  transition: background-color 0.3s ease;
}

.menu-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.menu-button:active {
  background-color: rgba(255, 255, 255, 0.3);
}

/* Style for the dots (ellipsis icon) */
.menu-button i {
  font-size: 1.5rem;
}

/* Greeting Text */
.greeting {
  font-size: 2rem;
  color: #4CAF50;
  font-weight: 700;
  margin: 20px 0 0px 0;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

/* Introductory Text */
.intro {
  font-size: 1.3rem;
  font-weight: 600;
  color: #006699;
  margin-bottom: 20px;
  text-align: center;
  line-height: 1.5;
  font-family: 'Montserrat', sans-serif;
}

/* Instruction Text */
.instruction {
  font-size: 1.5rem;
  color: #6CAB3D;
  font-weight: 500;
  margin-bottom: 25px;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  margin-top: 20px;
}

/* Conversation container */
/* Adjust conversation container height and padding */
.conversation-container {
  background-color: #1c1c1e;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 100px;
  padding-bottom: 100px; /* Add enough bottom padding so bubbles don't get cut off */
  height: calc(100vh - 200px); /* Adjust height so bubbles fit fully */
  overflow-y: auto;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
}
/* Conversation bubbles */
.chat-bubble {
  position: relative; /* Enable absolute positioning for avatar */
  display: flex;
  align-items: flex-start;
  padding: 15px 20px;
  max-width: 80%;
  word-wrap: break-word;
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: 1.5;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  overflow-wrap: break-word; /* Ensure text wraps properly */
}

/* Chat bubble content */
.chat-content {
  flex: 1; /* Ensure content takes up the remaining space */
  margin-left: 10px;
}

/* Icon styles (Avatar positioning for User and Bot) */
.chat-icon {
  width: 30px; /* Adjust icon size */
  height: 30px;
}

/* User avatar positioned to the top-right */
.user-avatar {
  position: absolute;
  top: -15px;
  right: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Bot avatar positioned to the top-left */
.bot-avatar {
  position: absolute;
  top: -15px;
  left: -15px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Media container to align map and image side by side */
.chat-media-container {
  display: flex;
  gap: 10px; /* Space between the map and image */
  align-items: center; /* Ensure images are vertically aligned */
}

/* Map styling inside the bubble */
.chat-bubble-map img {
  width: 100px; /* Small fixed width for map */
  height: 100px; /* Fixed height for map */
  border-radius: 10px;
  object-fit: cover; /* Ensure the image fits within the set size */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

/* Image styling inside the bubble */
.chat-bubble-image img {
  width: 100px; /* Small fixed width for image */
  height: 100px; /* Fixed height for image */
  border-radius: 10px;
  object-fit: cover; /* Ensure the image fits within the set size */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}

/* User chat bubble */
.user-bubble {
  background: linear-gradient(135deg, #516CF7, #6e55ef); /* Gradient for user bubbles */
  color: white;
  align-self: flex-end;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 25px 0px 25px 25px; /* 25px all sides except top-right */
  display: flex;
  justify-content: flex-end; /* Align content to the right */
}

.bot-bubble {
  background: #c1c1c1;
  color: black;
  align-self: flex-start;
  border-radius: 25px;
  width: auto;
  max-width: 90%;
  overflow-wrap: break-word; /* Ensure text wraps properly */
  padding-right: 10px;
  margin-left: 15px; /* Added margin to the left of bot bubbles */
}


/* Input field at the bottom of the screen */
.message-input {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px;
  background-color: #ffffff;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.input-field {
  flex-grow: 1;
  padding: 12px 15px;
  border: none;
  border-radius: 25px;
  background-color: #474747;
  font-size: 1rem;
  outline: none;
  margin-right: 10px;
  margin-left: 10px;
}

.send-button,
.speech-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.send-button:hover,
.speech-button:hover {
  background-color: #0056b3;
}

.icon {
  width: 20px;
  height: 20px;
}

/* Typing indicator (loader) */
.typing-indicator {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
}

.typing-indicator span {
  background-color: #4d4d4d;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 5px;
  animation: bounce 0.6s infinite alternate;
}

/* Bot image bubble */
.bot-image-bubble {
  background: none; /* Remove background for image bubbles */
  align-self: flex-start;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0; /* Remove padding */
}

/* Image container */
.chat-image {
  width: 100%;
  max-width: 300px; /* Adjust image size as needed */
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Image content */
.image-content {
  width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

/* Image title styling */
.image-title {
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 8px;
  text-align: left;
  color: #333;
  padding-left: 5px;
}

/* Enlarged Image Modal */
.enlarged-image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000; /* Ensure modal is on top */
}

.enlarged-image-content {
  position: relative;
  width: 80%;
  max-width: 800px; /* Limit maximum size */
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.enlarged-image-content img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  max-height: 80vh; /* Prevent image from overflowing */
  object-fit: contain;
}

/* Close button for the modal */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff4b4b;
  color: white;
  font-size: 2rem;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  z-index: 2001; /* Ensure close button is on top of modal */
}

.close-button:hover {
  background-color: #ff2b2b;
}

.download-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #4CAF50;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
}

.download-button:hover {
  background-color: #45a049;
}

/* Expandable sections styling */
.expandable-sections {
  margin-top: 10px;
}

.expandable-section {
  border: 1px solid #ddd;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  width: 100%; /* Ensure it fits the parent container (chat bubble) */
  max-width: 100%; /* Prevent it from going beyond the parent width */
  box-sizing: border-box; /* Include padding in width calculation */
  overflow-wrap: break-word; /* Ensure long content wraps properly */
  word-wrap: break-word;
  white-space: normal; /* Allow text to wrap in small screens */
  overflow: hidden; /* Prevent overflow */
}


.expandable-section h4 {
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  color: #000000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expandable-section p {
  margin: 0;
  padding: 10px 5px;
  font-size: 0.9rem;
  color: #333;
  font-style: italic;
  white-space: normal; /* Ensure text wraps within the container */
  word-wrap: break-word;
}

.expandable-section .arrow-icon {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}

.new-chat-button {
  background-color: #516CF7;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  margin: 10px 0;
  width: 100%; /* Full width button */
  text-align: center;
}

.new-chat-button:hover {
  background-color: #0066cc;
}

.final-output {
  border: 1px solid #ddd;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}
.final-output h4 {
  margin: 0 0 5px;
  color: #000000; /* Black text */
}
.final-output p {
  margin: 0;
  font-style: italic;
  color: #333;
}

.chart-container {
  width: 100%;
  height: 300px;
  max-width: 100%; /* Prevent chart overflow */
  overflow: hidden;
}

.sql-query-box {
  background-color: #f4f4f4;
  border-left: 4px solid #007bff;
  padding: 10px;
  margin: 10px 0;
  font-family: 'Courier New', Courier, monospace;
  color: #333;
  overflow-x: auto;
}

.sql-query-box pre {
  margin: 0;
  white-space: pre-wrap; /* Ensure the SQL query wraps correctly */
}

.reasoning-content p {
  margin-bottom: 10px;
  color: #333;
  font-size: 14px;
}

.visualization-table {
  width: 100%; /* Ensure the table takes up the full width */
  border-collapse: collapse; /* Remove space between table borders */
  margin-top: 20px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.visualization-table th, .visualization-table td {
  border: 1px solid #ddd; /* Light borders between cells */
  padding: 12px; /* Add padding for readability */
  text-align: left; /* Align text to the left */
  font-size: 14px; /* Set a comfortable font size */
}

.visualization-table th {
  background-color: #f2f2f2; /* Light background for headers */
  font-weight: bold; /* Make headers bold */
  text-transform: uppercase; /* Uppercase headers for better distinction */
  text-align: center; /* Center-align the header text */
}

.visualization-table tr:nth-child(even) {
  background-color: #f9f9f9; /* Alternating row colors */
}

.visualization-table tr:hover {
  background-color: #f1f1f1; /* Highlight row on hover */
}

.visualization-table td {
  text-align: center; /* Center-align the cell text */
  vertical-align: middle; /* Vertically center the cell text */
}

/* Restore the conversation buttons container to keep layout but hide the buttons */
.conversation-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
}

/* Hide the buttons but keep the layout intact */
.conversation-button {
  display: none; /* This hides the buttons while keeping their layout space */
}

/* Dark theme background and text */
body {
  background-color: #1c1c1e;
  color: white;
  font-family: 'Montserrat', sans-serif;
}


/* Sidebar (make sure this is only visible on larger screens) */
.sidebar {
  width: 25%;
  background-color: #2c2c2e;
  padding: 20px;
  height: 100vh;  /* Full height */
  position: fixed;
  top: 80px;  /* Below the top header */
  left: 0;
  overflow-y: auto;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  color: #b3b3b3;
  z-index: 999;
}

.sidebar h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #e0e0e0;
  margin-bottom: 20px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  padding: 15px;
  background-color: #3a3a3c;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;
  color: #e0e0e0;
  font-size: 1.2rem;
}

.sidebar ul li:hover {
  background-color: #5a5a5c;
  color: white;
}

/* Bottom line text */
.sidebar-bottom-text {
  padding-top: 20px;
  color: #a9a9a9;
  font-size: 0.9rem;
  text-align: center;
  border-top: 1px solid #444; /* Optional: adds a top border to separate the text */
  margin-top: auto; /* Ensure it stays at the bottom */
}

/* Main conversation container */
.main-conversation {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 80px);  /* Full height minus the height of the fixed header */
  overflow-y: auto;   /* Allow vertical scrolling within the conversation area */
  background-color: #1c1c1e;  /* Optional background */
  padding: 20px;
  box-sizing: border-box;
}

.top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #1c1c1e;
}

.logo-left, .logo-right {
  height: 30px; /* Adjust logo size for mobile */
  z-index: 1000;
}

.conversation-options {
  margin-top: 20px;
}

.conversation-button {
  background-color: #1f75fe;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1rem;
  margin: 5px;
  transition: background-color 0.3s ease;
}

.conversation-button:hover {
  background-color: #0066cc;
}

.message-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(75% - 60px);  /* Adjusted width to 75% of the chat window, excluding the sidebar */
  max-width: 800px; /* Increased max-width for better alignment */
  padding: 10px;
  position: fixed;
  bottom: 10px;
  left: calc(50% + 12.5%);  /* Adjusted left position to account for the sidebar */
  transform: translateX(-50%);
  background-color: #474747;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
  border-radius: 40px;
  z-index: 1000;
  box-sizing: border-box;
}

.input-field {
  flex-grow: 1;
  padding: 12px 15px;
  border: none;
  border-radius: 25px;
  background-color: #474747;
  font-size: 1rem;
  color: white;
  outline: none;
  margin-right: 10px;
  margin-left: 10px;
}

.send-button,
.speech-button {
  background-color: #516CF7;
  color: white;
  border: none;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
}

.send-button {
  margin-right: 10px; /* Added space between send button and mic button */
}

.send-button:hover,
.speech-button:hover {
  background-color: #516CF7;
}

.icon {
  width: 20px;
  height: 20px;
}

/* Hamburger Menu */
.hamburger-menu {
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 30px;
  cursor: pointer;
  z-index: 1001;
  color: white;
  display: none; /* Hide on larger screens */
}


/* Sidebar slide-in for mobile */
.sidebar-open {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;
  height: 100vh;
  background-color: #2c2c2e;
  padding: 20px;
  z-index: 1002;
  transform: translateX(0);
  transition: transform 0.3s ease;
}

/* Additional Info Styling */
.sidebar .additional-info {
  font-size: 1.05rem;
  margin-top: 20px;
  line-height: 1.6;
}

.sidebar .additional-info h4 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 15px;
}

.sidebar .additional-info h5 {
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 10px;
}

.sidebar .additional-info ul {
  padding-left: 10px;
}

.sidebar .additional-info ul li {
  list-style-type: disc;
  margin-bottom: 8px;
  font-size: 0.9rem;
}

/* Sidebar Scrollable */
.sidebar {
  overflow-y: auto;
  max-height: 90vh;
}

/* Toggle icons for dropdowns */
.toggle-icon {
  margin-left: 10px;
  width: 15px;
  height: 15px;
}

.sidebar .data-structure h5
{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding-left: 15px; /* Indent sub-dropdowns */
}
.sidebar h4 {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.sidebar .data-structure ul {
  list-style-type: none;
  padding-left: 15px;
  margin-top: 5px;
  padding-left: 35px; /* Indent sub-dropdowns */
}

.sidebar .data-structure ul li {
  font-size: 0.95rem;
  padding: 3px 0;
}


@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-6px);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .sidebar {
    display: none;
  }

  .hamburger-menu {
    display: block; /* Show hamburger menu on mobile */
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 30px;
    cursor: pointer;
    z-index: 1001;
    color: white;
  }
  .home-page {
    max-width: 100%;
    padding: 0 10px;
  }

  .top-header-title {
    font-size: 1rem;
  }

  .logo-left{
    height: 20px; /* Adjust logo size for smaller screens */
    margin-left: 10px;
  }

  .logo-right {
    height: 20px; /* Adjust logo size for smaller screens */
    margin-right: 10px;
    z-index: 1000;
  }

  .conversation-button {
    padding: 8px 7px;
    font-size: 1rem;
  }

  .message-input {
    width: calc(100% - 20px); /* Adjust width for mobile */
    padding: 10px 15px;
    bottom: 20px;
  }

  .input-field {
    padding: 10px 12px;
    font-size: 0.9rem;
  }

  .send-button,
  .speech-button {
    width: 40px;
    height: 40px;
  }

  .icon {
    width: 18px;
    height: 18px;
  }

  .avatar-image {
    width: 100px;
    height: 100px;
  }

  /* Stack map and image vertically on smaller screens */
  .chat-media-container {
    flex-direction: column;
  }

  .chat-bubble-map img,
  .chat-bubble-image img {
    width: 100%; /* Ensure full width on smaller screens */
    height: auto;
  }

  /* Adjust main conversation layout for mobile view */
  .main-conversation {
    width: 100%;
    height: calc(100vh - 80px);  /* Full height minus the fixed header */
    padding: 10px;               /* Adjust padding for smaller screens */
    box-sizing: border-box;
  }

  .conversation-bubbles {
    height: 100%;  /* Ensure conversation bubbles take up the full height */
    overflow-y: auto;  /* Allow scrolling within the conversation area */
  }

  .chat-bubble {
    max-width: 100%; /* Ensure chat bubbles take full width */
    padding: 12px;
    font-size: 0.9rem;
  }

  .user-bubble, .bot-bubble {
    max-width: 100%;
  }
  .message-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 40px); /* Adjust width to fit screen with padding */
    max-width: 500px; /* Set a maximum width for larger screens */
    background-color: #474747;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 40px;
    z-index: 1000;
    box-sizing: border-box;
  }

  /* Sidebar hidden and shown on mobile with transitions */
  .sidebar-closed {
    display: block;
    transform: translateX(-100%); /* Hide sidebar off-screen */
    transition: transform 0.3s ease;
  }

  .sidebar-open {
    display: block;
    transform: translateX(0); /* Slide sidebar into view */
    transition: transform 0.3s ease;
  }
  


  .close-sidebar {
    display: block; /* Show close button on mobile */
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 25px;
    cursor: pointer;
    z-index: 1002;
    color: white;
  }

  /* Make sure the top-header is fixed and doesn't scroll */
  .top-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
}

/* Sidebar visible on desktop (min-width 768px) */
@media (min-width: 769px) {
  .sidebar {
    display: block !important; /* Always show sidebar on desktop */
    width: 25%;
    position: fixed; /* Fix to the left */
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #1c1c1e; /* Sidebar color */
    z-index: 999;
    overflow-y: auto;
  }

  .main-conversation {
    margin-left: 27%; /* Leave extra space for the sidebar */
    width: 70%; /* Adjust the width to fit the space properly */
    padding-left: 20px; /* Add padding to create space between the sidebar and the bot messages */
  }

  .hamburger-menu {
    display: none; /* Hide hamburger menu on desktop */
  }

  .close-sidebar {
    display: none; /* Hide close button on desktop */
  }
}

@media (max-width: 400px) {
  .sidebar {
    display: block !important; /* Ensure sidebar is always displayed */
    width: 100%; /* Sidebar takes full width */
    height: 100vh;
    position: relative; /* Allow sidebar to take up full screen */
    box-shadow: none; /* Remove any shadow */
  }

  .hamburger-menu {
    display: none; /* Hide the hamburger menu since the sidebar is always visible */
  }

  .main-conversation {
    margin-left: 0; /* No margin needed for main conversation since sidebar is full width */
    width: 100%; /* Take full width for the conversation area */
    padding-top: 20px; /* Ensure padding at the top */
  }

  .top-header {
    padding: 20px; /* Ensure padding for header */
  }
}

