/* Full-screen container for GPT Selection */
.fs_first-screen-container {
  height: 100vh;  /* Full height of the viewport */
  width: 100vw;   /* Full width of the viewport */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000;
  color: white;
  overflow: hidden;  /* Prevent any scrolling */
}

/* Top section for the logo */
.fs_header {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  width: 100%;
  z-index: 10;
}

/* Right logo */
.fs_right-logo {
  width: 100px;
  margin-right: 20px;
}

/* Main content: Two-column layout */
.fs_main-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%; /* Ensure full width */
  padding: 0 50px;
  overflow: hidden;  /* Ensure no overflow */
}

.fs_left-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fs_map-image-inline {
  width: 100%;  /* Adjust image width to fit container */
  height: auto;
  max-width: 800px; /* Set maximum width for large screens */
  object-fit: cover; /* Ensure the image scales properly */
}

.fs_map-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Right-side content */
.fs_right-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
}

.fs_content {
  max-width: 450px;
  text-align: left;
}

h1 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.gpt-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.gpt-option {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  width: 300px;
  padding: 15px 30px;
  border-radius: 35px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.gpt-option.selected {
  background-color: white;
  color: #000;
}

.gpt-option:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Center the Next button horizontally */
.fs_button-wrapper {
  margin-top: 20px;
  width: 300px; /* Ensure it matches the GPT option buttons */
  display: flex;
  justify-content: center;
}

.fs_next-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
}

.fs_next-button:disabled {
  color: #555; /* Disabled button style */
  cursor: not-allowed;
}

.fs_next-button:not(:disabled):hover {
  color: #888; /* Optional hover effect */
}

/* Responsive scaling for tablet and mobile screens */
@media (max-width: 768px) {
  .fs_main-content {
    flex-direction: column; /* Stack content vertically */
    padding: 20px;
    justify-content: center;
    align-items: center;
  }

  .fs_map-image-inline {
    width: 100%; /* Ensure the image scales down properly */
  }

  .fs_left-side, .fs_right-side {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .fs_content {
    padding: 0;
    text-align: center;
  }

  h1 {
    font-size: 2.5rem; /* Adjust font size for mobile */
  }

  .gpt-option {
    width: 100%;
    max-width: 250px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2rem; /* Smaller heading for mobile */
  }

  .gpt-option {
    padding: 12px 20px;
    font-size: 1.2rem;
  }

  .fs_next-button {
    font-size: 1.2rem;
  }
}