/* Full-screen container for the User Input Screen */
.ui_first-screen-container {
    height: 100vh;  /* Full height of the viewport */
    width: 100vw;   /* Full width of the viewport */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #000;
    color: white;
    overflow: hidden;  /* Prevent scrolling */
    position: relative; /* Ensure the elements stay within container */
  }
  
  /* Top section for the logo */
  .ui_header {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    width: 100%; /* Ensure full width */
    z-index: 10;
  }
  
  /* Right logo */
  .ui_right-logo {
    width: 100px;
    margin-right: 20px;
  }
  
  /* Main content layout */
  .ui_main-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%; /* Ensure full width */
    padding: 0 50px;
    overflow: hidden;  /* Prevent content from overflowing */
  }
  
  .ui_left-side {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ui_map-image-inline {
    width: 100%;  /* Adjust image width to fit container */
    height: auto;
    max-width: 800px; /* Set maximum width for large screens */
    object-fit: cover; /* Ensure the image scales properly */
  }
  
  .ui_map-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  /* Right-side content */
  .ui_right-side {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
  }
  
  .ui_content {
    max-width: 500px;
    text-align: left;
  }
  
  h1 {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .ui_input {
    width: 70%;
    padding: 15px;
    font-size: 1.2rem;
    border-radius: 35px;
    border: 1px solid white;
    color: white;
    background-color: transparent;
    outline: none;
    margin-bottom: 20px;
  }
  
  .ui_input::placeholder {
    color: #888;
  }
  
  /* Center the button properly */
  .ui_button-wrapper {
    display: flex;
    justify-content: center; /* Ensure the button is centered horizontally */
    width: 100%; /* Full width to allow proper centering */
    margin-top: 20px;
  }
  
  .ui_next-button {
    background: none;
    border: none;
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    cursor: pointer;
  }
  
  .ui_next-button:hover {
    color: #888; /* Optional hover effect */
  }
  
  .ui_next-button:disabled {
    color: #555; /* Make the disabled button grayed out */
    cursor: not-allowed; /* Change the cursor to indicate it's disabled */
  }
  
  /* Suggestions dropdown */
  .ui_suggestions {
    position: absolute; /* Make sure the dropdown stays below the input */
    z-index: 100;       /* Ensure the dropdown appears above other content */
    width: 100%;        /* Ensure dropdown matches the input width */
    max-width: 500px;   /* Set a maximum width for the dropdown */
    max-height: 150px;  /* Limit the height of the dropdown */
    overflow-y: auto;   /* Enable vertical scrolling when necessary */
    background-color: #222;
    border-radius: 10px;
    border: 1px solid #333;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .ui_suggestion-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .ui_suggestion-item:hover {
    background-color: #444;
  }
  
  .ui_no-suggestion {
    padding: 10px;
    color: #888;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .ui_main-content {
      flex-direction: column;
      padding: 20px;
      justify-content: center;
      align-items: center;
    }
  
    .ui_left-side, .ui_right-side {
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  
    .ui_content {
      padding: 0;
      text-align: center;
    }
  
    h1 {
      font-size: 2.5rem;
    }
  
    .ui_input {
      padding: 12px;
      font-size: 1.1rem;
    }
  
    .ui_next-button {
      width: auto;  /* Set button width to auto */
      font-size: 1.2rem;
    }
  
    /* Center the button even on mobile */
    .ui_button-wrapper {
      justify-content: center; /* Center the button horizontally */
      width: 100%; /* Ensure full width for centering */
    }
  }
  
  @media (max-width: 480px) {
    h1 {
      font-size: 2rem;
    }
  
    .ui_input {
      font-size: 1rem;
      padding: 13px;
      margin-right: 20px;
    }
  
    .ui_next-button {
      padding: 12px 20px;
      font-size: 1.1rem;
    }
  
    /* Mobile-specific centering for the button */
    .ui_button-wrapper {
      justify-content: center; /* Center the button horizontally */
      width: 100%; /* Ensure full width for centering */
    }
  }