/* Full-screen container for the FirstScreen */
.fs_first-screen-container {
  height: 100vh;  /* Full height of the viewport */
  width: 100vw;   /* Full width of the viewport */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #000;
  color: white;
  overflow: hidden;  /* Prevent any scrolling */
  position: relative; /* Ensure all elements stay within the container */
}

/* Header section for the logo */
.fs_header {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  width: 100%; /* Ensure full width */
  z-index: 10;
}

/* Right logo */
.fs_right-logo {
  width: 100px;
  margin-right: 20px;
}

/* Main content layout */
.fs_main-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 40px); /* Adjust height to avoid overflow */
  width: 100%; /* Ensure full width */
  padding: 0 50px;
  overflow: hidden;  /* Prevent content overflow */
}

.fs_left-side {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fs_map-image-inline {
  width: 100%;  /* Adjust image width to fit container */
  height: auto;
  max-width: 800px; /* Set maximum width for large screens */
  object-fit: cover; /* Ensure the image scales properly */
}

.fs_map-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Right-side content */
.fs_right-side {
  flex: 1;
  display: flex;
  justify-content: center; /* Center-align text and button */
  align-items: center;
  text-align: center; /* Center-align text */
}

.fs_content {
  max-width: 500px;
  text-align: center; /* Ensure center alignment */
  overflow-wrap: break-word; /* Ensure text wraps properly */
}

h1 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.fs_tagline {
  font-size: 1.3rem;
  margin-bottom: 20px;
}

.fs_primary-button {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 15px 30px;
  border-radius: 35px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block;
  margin: 0 auto;  /* Center the button */
}

.fs_primary-button:hover {
  background-color: white;
  color: black;
}

.fs_primary-button span {
  font-size: 1.5rem;
  margin-left: 5px;
}

/* Responsive scaling for mobile and tablet screens */
@media (max-width: 768px) {
  .fs_main-content {
    flex-direction: column; /* Stack content vertically */
    padding: 20px;
    justify-content: center;
    align-items: center;
  }

  .fs_map-image-inline {
    width: 100%; /* Ensure the image scales down properly */
  }

  .fs_left-side, .fs_right-side {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .fs_content {
    padding: 0;
    text-align: center;
  }

  h1 {
    font-size: 2.5rem; /* Adjust font size for mobile */
  }

  .fs_primary-button {
    width: 100%;
    max-width: 250px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2rem; /* Adjust heading size for smaller screens */
  }

  .fs_primary-button {
    padding: 12px 20px;
    font-size: 1.1rem;
  }
}